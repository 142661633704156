const initState = {
  config: [],
  report_controlled: null,
  dropdownsData: null,
  vendor_reg_table_initiator: true,
  vendor_reg_table_verifier: true,
  vendor_reg_table_approver: true,
  vendor_reg_details: [],
  vendor_assign_details: [],
  vendor_reg_section_details: [],
  vendor_reg_contact_details: [],

  vendor_reg_vendor_details: [],
  vendor_reg_tableList: null,
  vendor_reg_draftList: null,
  vendor_reg_report_nos: null,
  vendor_reg_report: null,
  vendor_reg_filter: {},
  vendor_reg_seq_c: [],
  vendor_reg_seq_p: [],
  vendor_reg_users_list: [],
  vendor_reg_notification_details: [],
  vendor_reg_activity_list: [],
  vendor_reg_attachments: [],
  stageData: null,
  error: null,
  isVendorForwardSkipped: false,
  requestValue: true,
  loading: false,
  success: true,
  currentStep: 0,
  genbotAnalytics: {
    loading: false,
    data: null,
    error: null
  },
  genbotTextCorrection:null,
};

const vmmReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CCM_CONFIG':
      return {
        ...state,
        config: action.data,
        report_controlled: action.data.report_controlled,
      };
    case 'SET_VMM_DROPDOWN_VALUES':
      return {
        ...state,
        dropdownsData: action.data,
      };
    case 'SET_VR_TABLE_INITIATOR':
      console.log(action.data, ':SET_VR_TABLE_INITIATOR...............');

      return {
        ...state,
        vendor_reg_table_initiator: action.data,
      };
    case 'SET_VR_TABLE_VERIFIER':
      return {
        ...state,
        vendor_reg_table_verifier: action.data,
      };
    case 'SET_VR_TABLE_APPROVER':
      return {
        ...state,
        vendor_reg_table_approver: action.data,
      };

    case 'SET_REQUEST_VALUE':
      console.log(
        action.payload,
        ':request value in the redux da kamesh......'
      );

      return {
        ...state,
        requestValue: action.payload,
      };
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
      case' GENBOT_TEXT_ANALYTIC_REQUEST':
      return {
        ...state,
        genbotAnalytics: {
          loading: true,
          data: null,
          error: null
        }
      };
    
    case 'GENBOT_TEXT_ANALYTIC_SUCCESS':
      console.log("GENBOT_TEXT_ANALYTIC_SUCCESS Action Dispatched:", action.payload);
      return {
        ...state,
        genbotAnalytics: {
          loading: false,
          data: action.payload,
          error: null
        }
      };
    
    case 'GENBOT_TEXT_ANALYTIC_FAILURE':
      return {
        ...state,
        genbotAnalytics: {
          loading: false,
          data: null,
          error: action.payload
        }
      };
       case' GENBOT_CORRECT_SUCCESS':
                console.log("GENBOT_CORRECT_SUCCESS Action Dispatched:", action.payload);
                return {
                  ...state,
                  genbotTextCorrection: action.payload,
                };
    // case 'GET_VENDOR_REG_DETAILS':
    //   console.log('Action payload for GET_VENDOR_REG_DETAILS:', action.data);
    //   console.log(
    //     action.data?.data?.vendorDetails,
    //     ':vendor action data(vendor details da kamesh) from api response'
    //   );
    //   const updatedState = {
    //     ...state,
    //     vendor_reg_details: action.data?.['vendor_details'],
    //     vendor_reg_section_details: action.data?.['section_details'],
    //     vendor_reg_contact_details: action.data?.data?.contact,
    //   // vendor_reg_vendor_details: action.data?.data?.vendorDetails,
    //   vendor_assign_details:action.data?.vendorDetails,
    //     vendor_reg_vendor_details: action.data?.['vendor_details'],
    //     vendor_reg_attachments: action.data?.['attachment_details'],
    //   };

    //   console.log('Updated state after GET_VENDOR_REG_DETAILS:', updatedState);

    //   return updatedState;
    case 'GET_VENDOR_REG_DETAILS':
      console.log(action.data,":vendor action data from api response");
      console.log(action.data?.data?.vendorDetails ,":vendor action data(vendor details da kamesh) from api response");
      return {
        ...state,
        vendor_reg_details: action.data?.['vendor_details'],
        vendor_reg_section_details: action.data?.['section_details'],
        vendor_reg_contact_details: action.data?.data?.contact,
        vendor_reg_vendor_details: action.data?.data?.vendorDetails,
         
        vendor_reg_attachments: action.data?.['attachment_details'],
      };
    case 'GET_VENDOR_ASSIGN_DETAILS':
      console.log(
        action.data?.vendorDetails,
        ':vendor action data(vendor details da kamesh) from api response'
      );

      return {
        ...state,

        vendor_assign_details: action.data?.vendorDetails,
      };

    case 'VENDOR_FORWARD_SUCCESS':
      return {
        ...state,
        isVendorForwardSkipped: false,
      };

    case ' VENDOR_REVERT_SUCCESS':
      return {
        ...state,
        loading: false,
        success: true,
      };
    case 'GET_VENDOR_REG_TABLE_LIST':
      return {
        ...state,
        vendor_reg_tableList: action.data,
      };
    case 'GET_VENDOR_REG_DRAFT_LIST':
      return {
        ...state,
        vendor_reg_draftList: action.data,
      };
    case 'GET_VENDOR_REG_REPORT_AND_LOGS':
      return {
        ...state,
        vendor_reg_report: action.vendor_reg_report,
        vendor_reg_report_nos: action.vendor_reg_report_nos,
      };
    case 'GET_VENDOR_REG_REPORT_AND_LOGS_FILTER':
      return {
        ...state,
        vendor_reg_filter: action.vendor_reg_filter,
      };

    case 'VENDOR_REG_SEQUENCE_NO':
      return {
        ...state,
        vendor_reg_filter: action.vendor_reg_filter,
        vendor_reg_seq_c: action.vendor_reg_seq_c,
        vendor_reg_seq_p: action.vendor_reg_seq_p,
      };
    case 'VENDOR_REG_SUGGEST_USERS':
      return {
        ...state,
        vendor_reg_users_list: action.data,
      };

    case 'VENDOR_REG_NOTIFICATION_DETAILS':
      return {
        ...state,
        vendor_reg_notification_details: action.data,
      };
    case 'TRANSITION_STAGE_SUCCESS':
      return {
        ...state,
        stageData: action.data,
        error: null,
      };
    case 'TRANSITION_STAGE_FAILURE':
      return {
        ...state,
        stageData: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default vmmReducer;
