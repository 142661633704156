import { iaccessHttps } from '../../../utils/config';
import { message } from 'antd';
export const SET_MASTER_DATA = 'SET_MASTER_DATA';

export const SET_QUESTION_DATA = 'SET_QUESTION_DATA';
export const SET_QUESTION_NO = 'SET_QUESTION_NO';
export const SET_SECTION_NO = 'SET_SECTION_NO';
export const GET_QUESTION_PAPER_DATA = 'GET_QUESTION_PAPER_DATA';
export const GET_QUESTION_PAPER = 'GET_QUESTION_PAPER';
export const SET_SUB_QUESTION = 'SET_SUB_QUESTION';

const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const addMasterData = (data, values) => (dispatch) => {
  console.log(data, values);
  const result = [...data, values];
  dispatch({ type: SET_MASTER_DATA, data: result });
};

export const updateMasterData = (data, values) => (dispatch) => {
  const dataIndex = data.findIndex(
    (item) => item.section_id === values.section_id
  );
  data[dataIndex] = values;
  dispatch({ type: SET_MASTER_DATA, data: data });
};

export const deleteMasterData = (data, values) => (dispatch) => {
  const dataIndex = data.findIndex(
    (item) => item.section_id === values.section_id
  );
  data.splice(dataIndex, 1);
  dispatch({ type: SET_MASTER_DATA, data: data });
};
export const deleteQuestionData =
  (data, sectionNo, QuestionNo) => (dispatch) => {
    const sectionIndex = data.findIndex(
      (item) => item.section_id === sectionNo
    );
    const questionIndex = data[sectionIndex].questions.findIndex(
      (item) => item.question_id === QuestionNo
    );
    data[sectionIndex].questions.splice(questionIndex, 1);
    dispatch({ type: SET_MASTER_DATA, data: data });
    dispatch({ type: SET_QUESTION_NO, data: null });
    dispatch({ type: SET_SECTION_NO, data: null });
  };

export const addQuestionData = (data, values) => (dispatch) => {
  console.log(data, values);
  const dataIndex = data.findIndex(
    (item) => item.section_id === values.section_id
  );
  data[dataIndex] = {
    ...data[dataIndex],
    questions: [
      ...data[dataIndex].questions,
      {
        question_id: `Q${data[dataIndex].questions?.length + 1}`,
        answer_type: null,
        question_name: '',
        question_type: false,
        dependency: false,
        attachment: false,
        tableFormat: false,
        showDisabled : false,
        comments: false,
        exclude: false,
        required: true,
      },
    ],
  };
  dispatch({ type: SET_MASTER_DATA, data: data });
};

export const reorderQuestion =
  (oldIndex, newIndex, data, index) => (dispatch) => {
    const sectionData = data[index];
    const questionData = [...sectionData.questions];
    const [removed] = questionData.splice(oldIndex, 1);
    questionData.splice(newIndex, 0, removed);
    sectionData.questions = questionData;
    data[index] = sectionData;
    dispatch({ type: SET_MASTER_DATA, data: data });
  };
export const reorderSection = (oldIndex, newIndex, data) => (dispatch) => {
  const [removed] = data.splice(oldIndex, 1);
  data.splice(newIndex, 0, removed);
  dispatch({ type: SET_MASTER_DATA, data: data });
};

export const setQuestionNo = (value) => (dispatch) => {
  dispatch({ type: SET_QUESTION_NO, data: value });
};
export const setSectionNo = (value) => (dispatch) => {
  dispatch({ type: SET_SECTION_NO, data: value });
};
export const setSubQuestion = (value) => (dispatch) => {
  dispatch({ type: SET_SUB_QUESTION, data: value });
};
export const setQuestionData = (value) => (dispatch) => {
  dispatch({ type: SET_QUESTION_DATA, data: value });
};
export const updateQuestionData = (data) => (dispatch) => {
  dispatch({ type: SET_MASTER_DATA, data: data });
};

export const getQuestionPaper = (data) => (dispatch) => {
  iaccessHttps
    .post('/getQuestionPaper/', data)
    .then((res) => {
      dispatch({ type: GET_QUESTION_PAPER, data: res.data });
    })
    .catch((err) => console.log(err));
};

export const QuestionPaperAction = (data) => (dispatch) => {
  iaccessHttps
    .post('/questionPaper/', data)
    .then((res) => {
      if (data.action_type == 'draft') {
        dispatch(getAllQuestionPaper());
        message.success('Form Added SuccessFully');
      }
      if (data.action_type == 'update') {
        message.success('Form Updated SuccessFully');
      }
      if (data.action_type == 'delete') {
        message.success('Form Deleted SuccessFully');
      }
    })
    .catch((err) => console.log(err));
};
export const getAllQuestionPaper = () => (dispatch) => {
  iaccessHttps
    .post('/getAllQuestionPaper/')
    .then((res) => {
      dispatch({ type: GET_QUESTION_PAPER_DATA, data: res.data });
    })
    .catch((err) => console.log(err));
};

export const GetQuestionPaperReturnValue = (data) => {
  return iaccessHttps
    .post('/getQuestionPaper/', data)
    .then((res) => {
      console.log('Received question paper data:', res.data); // Log the response data
      return res.data;
    })
    .catch((err) => {
      console.error('Error fetching question paper:', err); // Log the error
      return [];
    });
};
