import axios from 'axios';
export const SET_VMM_DROPDOWN_VALUES = 'SET_VMM_DROPDOWN_VALUES';
export const SET_VR_TABLE_INITIATOR = 'SET_VR_TABLE_INITIATOR';
export const SET_VR_TABLE_VERIFIER = 'SET_VR_TABLE_VERIFIER';
export const SET_VR_TABLE_APPROVER = 'SET_VR_TABLE_APPROVER';
export const CCM_CONFIG = 'CCM_CONFIG';
export const GENBOT_CORRECT_SUCCESS  = "GENBOT_CORRECT_SUCCESS ";
export const CCMConfig = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const data = {
      type: 'global_config',
    };
    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/globalConfig/`, data, config)
      .then(({ data }) => {
        dispatch({ type: CCM_CONFIG, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const getVmmDropdownValues = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/GetVMMFormDropdown/`,
        null,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_VMM_DROPDOWN_VALUES, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const setVendorRegTableRequest = (data) => (dispatch) => {
  try {
    const role = localStorage.getItem('role');
    if (role === 'vmm_initiator') {
      console.log(data, ':setVendorRegTableRequest data in vmm action');
      dispatch({ type: SET_VR_TABLE_INITIATOR, data: data });
    } else if (role === 'vmm_verifier') {
      dispatch({ type: SET_VR_TABLE_VERIFIER, data: data });
    }
  } catch (error) {
    console.error(error);
  }
};

export const sendVMMEmail = (data) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const name = localStorage.getItem('name');
    const username = localStorage.getItem('username');
    const organization = localStorage.getItem('organization');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const formBody = {
      ...data,
      action_by: `${name} (${username})`,
      organization: organization,
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/sendAMMEmail/`,
        formBody,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
export const GenbotCorrectText = (payload) => async (dispatch) => {
  console.log(payload, 'Sending GENBOT correction payload to API...');

  try {
    const token = localStorage.getItem('access_token');
    const response = await axios.post(
      "http://23.20.149.216/ai/TextCorrection/",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status < 200 || response.status >= 300) {
      const errorData = response.data;
      throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
    }

    // Axios response data is in response.data
    const responseData = response.data;
console.log(responseData,'responseData for text correction');

    dispatch({
      type: GENBOT_CORRECT_SUCCESS,
      payload: responseData,
    });
    console.log('DISPATCHED SUCCESS:', response.data);
    

  } catch (err) {
    // Enhanced error logging for Axios errors
    console.error("Error details:", err.message);
    if (err.response) {
      // Server responded with non-2xx status
      console.error("Server response error:", err.response.data);
    } else if (err.request) {
      // Request made but no response received
      console.error("No response received:", err.request);
    } else {
      // Request setup error
      console.error("Request configuration error:", err.message);
    }
    throw err;
  }
};