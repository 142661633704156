import * as types from '../actions/commonActions';
 
const initState = {
  assignees: null,
  nextAssignees: null,
  otherDocuments: [],
  fileLoader: false,
  uploadList: [],
  attachmentsList: null,
  purposes: null,
  imp_link_reason: [],
  filters: [],
  func_reviews: null,
  func_totalCount: 0,
  func_review_details: null,
  funct_area: [],
  funct_impact: [],
  risk_scale: [],
  responsible_persons: [],
  imp_tasks: null,
  tasks_totalCount: 0,
  imp_task_details: null,
  imp_task_details2: null,
  imp_task2_details: null,
  task_area: [],
  task_priority: [],
  task_attachment: [],
  excel_data: [],
  create_request_type: null,
  allRequest: false,
  initiatorStatus: null,
  vendor: null,
  report_controlled: null,
  documentDetails: {},
  genbotText: null,        
  genbotRephrase: null, 
  genbotTextCorrection:null,
  // genbotAnalytics: {
  //   loading: false,
  //   data: null,
  //   error: null
  // }
};

const common = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ASSIGNEE_LIST:
      return {
        ...state,
        assignees: action.data,
      };
      case types.GENBOT_GENERATE_SUCCESS:
        console.log("GENBOT_GENERATE_SUCCESS Action Dispatched:", action.payload);
        return {
          ...state,
          genbotText: action.payload,
        };
  
      case types.GENBOT_REPHRASE_SUCCESS:
        console.log("GENBOT_REPHRASE_SUCCESS Action Dispatched:", action.payload);
        return {
          ...state,
          genbotRephrase: action.payload,
        };
        case types.GENBOT_CORRECT_SUCCESS:
          console.log("GENBOT_CORRECT_SUCCESS Action Dispatched:", action.payload);
          return {
            ...state,
            genbotTextCorrection: action.payload,
          };
          // case types.GENBOT_TEXT_ANALYTIC_REQUEST:
          //   return {
          //     ...state,
          //     genbotAnalytics: {
          //       loading: true,
          //       data: null,
          //       error: null
          //     }
          //   };
          
          // case types.GENBOT_TEXT_ANALYTIC_SUCCESS:
          //   console.log("GENBOT_TEXT_ANALYTIC_SUCCESS Action Dispatched:", action.payload);
          //   return {
          //     ...state,
          //     genbotAnalytics: {
          //       loading: false,
          //       data: action.payload,
          //       error: null
          //     }
          //   };
          
          // case types.GENBOT_TEXT_ANALYTIC_FAILURE:
          //   return {
          //     ...state,
          //     genbotAnalytics: {
          //       loading: false,
          //       data: null,
          //       error: action.payload
          //     }
          //   };
        case types.GENBOT_GENERATE_RESET:
          console.log("GENBOT_GENERATE_RESET Action Dispatched:", action.payload);
  return {
    ...state,
    genbotText: null,
  };
  // case types.GENBOT_ANALYTICS_RESET:
  // return {
  //   ...state,
  //   genbotAnalytics: {
  //     loading: false,
  //     data: null,
  //     error: null
  //   }
  // };
  case types.GENBOT_REPHRASE_RESET:
  return {
    ...state,
    genbotRephrase: null,
  };
    case types.GET_NEXT_ASSIGNEES:
      return {
        ...state,
        nextAssignees: action.data,
      };

    case types.SET_NCO_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case types.UPLOAD_ATTACHMENTS:
      return {
        ...state,
        uploadList: [...state.uploadList, action.data],
        fileLoader: action.loading,
      };

    case types.UPDATE_ATTACHMENT:
      return {
        ...state,
        uploadList: action.data,
      };

    case types.FILE_LOADER:
      return {
        ...state,
        fileLoader: action.loading,
      };

    case types.DELETE_UPLOAD_ATTACHMENT:
      return {
        ...state,
        uploadList: state.uploadList.filter(
          (a) => a.s3_file_name !== action.s3_file_name
        ),
        attachmentsList:
          action.id && action.notUploded
            ? state.attachmentsList.filter((a) => a.id !== action.id)
            : state.attachmentsList,
      };

    case types.GET_ATTACHMENTS_LIST:
      return {
        ...state,
        attachmentsList: action.data,
      };

    case types.DELETE_ATTACHMENT:
      return {
        ...state,
        attachmentsList: state.attachmentsList.filter(
          (a) => a.s3_file_name !== action.s3_file_name
        ),
      };
    case types.allRequest:
      return { ...state, allRequest: action.payload };

    case types.CLEAR_ATTACHMENTS:
      return {
        ...state,
        uploadList: [],
        attachmentsList: [],
      };

    case types.GET_PURPOSE_LIST:
      return {
        ...state,
        purposes: action.data,
      };

    case types.RESPONSIBLE_PERSONS:
      return {
        ...state,
        responsible_persons: action.data,
      };

    case types.GET_FUNCTIONAL_REVIEW_LIST:
      return {
        ...state,
        func_reviews: action.data,
        func_totalCount: action.total,
        excel_data: action.excel_data,
      };

    case types.FUNCTIONAL_REVIEW_DETAILS:
      return {
        ...state,
        func_review_details: action.data,
      };

    case types.GET_IMP_TASK_LIST:
      return {
        ...state,
        imp_tasks: action.data,
        tasks_totalCount: action.total,
        excel_data: action.excel_data,
      };

    case types.IMP_TASK_DETAILS:
      return {
        ...state,
        imp_task_details: action.data,
      };
    case types.IMP_TASK_DETAILS2:
      return {
        ...state,
        imp_task_details2: action.data,
      };
    case types.IMP_EFFECTIVE_DETAILS:
      return {
        ...state,
        imp_task2_details: action.data,
      };
    case types.CREATE_REQUEST_TYPE:
      return {
        ...state,
        create_request_type: action.data,
      };
    case types.CAPA_CONFIG:
      return {
        ...state,
        initiatorStatus: action.data.initiator_status,
        vendor: action.data.vendor,
        report_controlled: action.data.report_controlled,

        // initiatorStatus: false,
      };

      case 'OTHER_DOCUMENTS':
        return {
          ...state,
          otherDocuments: action.data,
        };
        case 'DOCUMENTS_DETAILS':
        return {
          ...state,
          documentDetails: action.data,
        };
        case 'FILTER_DOCUMENT':
          return {
            ...state,
            filterDoc: action.data,
          };
    case types.SET_COMMON_ATTRIBUTES:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};

export default common;
