import {
  dncHttps,
  fetch,
  https,
  cmmHttps,
  idocHttps,
  mtfHttps,
  rmHttps,
  ncoHttps,
  ncotestHttps,
} from '../../utils/config';
import { NCOAudit } from '../../utils/helper';
import axios from 'axios';
import {
  getNextAssignee,
  getAttachmentsList,
  SET_COMMON_ATTRIBUTES,
  sendEmail,
  GET_NEXT_ASSIGNEES,
  CLEAR_ATTACHMENTS,
  getTaskDetails,
} from './commonActions';
import { getNCOAttachmentsList } from './nco/CAPAPlanInitiation';
import {
  DELETE_LINK_CHANGE,
  UPDATE_LINK_CHANGE,
} from './implementationActions';
import { hideModal } from './modalActions';
import { message } from 'antd';
import {
  formatExcelData,
  formatNcoExcelData,
  formatMTFDraftExcelData,
  formatMTFExcelData,
  formatDraftExcelData,
} from '../../utils/helper';
import {
  ini_assignee_alias,
  ini_stage_alias,
  ini_mail_stage,
  ini_route_back_alias,
  MODULE,
  mtf_mail_stage,
  nco_mail_stage,
  nco_stage_alias,
  mf_stage_alias,
  dnc_ini_stage_alias,
  rm_ini_stage_alias,
} from '../../utils/constants';
export const GET_EFFECTIVENESS_DATA = 'GET_EFFECTIVENESS_DATA';
export const GET_RETIRE_DETAILS = 'GET_RETIRE_DETAILS';
export const GET_INITIATION_DATA = 'GET_INITIATION_DATA';
export const GET_DRAFT_DATA = 'GET_DRAFT_DATA';
export const INITIATION_DETAILS = 'INITIATION_DETAILS';
export const ACTION_ITEM_DETAILS = 'ACTION_ITEM_DETAILS';
export const INITIATION_FORM = 'INITIATION_FORM';
export const CLEAR_INITIATION_DETAILS = 'CLEAR_INITIATION_DETAILS';
export const CLEAR_INITIATION_FORM = 'CLEAR_INITIATION_FORM';
export const ACTIVITY_LIST = 'ACTIVITY_LIST';
export const ACTIVITY_IMPLIST = 'ACTIVITY_IMPLIST';
export const UPDATE_ASSOCIATE_CHANGE = 'UPDATE_ASSOCIATE_CHANGE';
export const UPDATE_PRODUCT_CHANGE = 'UPDATE_PRODUCT_CHANGE';
export const DELETE_ASSOCIATE_CHANGE = 'DELETE_ASSOCIATE_CHANGE';
export const DELETE_PRODUCT_CHANGE = 'DELETE_PRODUCT_CHANGE';
export const DELETE_LINK_REQUEST = 'DELETE_LINK_REQUEST';
export const UPDATE_LINK_REQUEST = 'UPDATE_LINK_REQUEST';
export const INI_MODAL_DETAILS = 'INI_MODAL_DETAILS';
export const INI_DETAILS_ERR = 'INI_DETAILS_ERR';
export const INI_SEARCH = 'INI_SEARCH';
export const UPDATE_FUNCTIONAL_REVIEW = 'UPDATE_FUNCTIONAL_REVIEW';
export const DELETE_FUNC_REVIEW = 'DELETE_FUNC_REVIEW';
export const SET_DROPDOWN_VALUES = 'SET_DROPDOWN_VALUES';
export const SET_NCO_DROPDOWN_VALUES = 'SET_NCO_DROPDOWN_VALUES';
export const SET_PROJECT_VALUES = 'SET_PROJECT_VALUES';
export const REMOVE_DRAFT_ROW = 'REMOVE_DRAFT_ROW';
export const DOC_NO_LIST = 'DOC_NO_LIST';
export const DOC_NO_DETAIL = 'DOC_NO_DETAIL';
export const CLEAR_DOC_NO_LIST = 'CLEAR_DOC_NO_LIST';
export const CLEAR_DOC_NO_DETAIL = 'CLEAR_DOC_NO_DETAIL';
export const SET_LOADER = 'SET_LOADER';
export const EDIT_LINK_CHANGE_REQUEST = 'EDIT_LINK_CHANGE_REQUEST';
export const DELETE_LINK_CHANGE_REQUEST = 'DELETE_LINK_CHANGE_REQUEST';
export const GET_ACTION_ITEM_NOTIFICATION = 'GET_ACTION_ITEM_NOTIFICATION';
export const ADD_ACTION_ITEM_NOTIFICATION = 'ADD_ACTION_ITEM_NOTIFICATION';
export const SET_RCA = 'SET_RCA';
export const SET_CAPA = 'SET_CAPA';
export const NCO_DELETE_LINK_REQUEST = 'NCO_DELETE_LINK_REQUEST';
export const NCO_DELETE_LINK_CHANGE_REQUEST = 'NCO_DELETE_LINK_CHANGE_REQUEST';
export const NCO_UPDATE_LINK_CHANGE = 'NCO_UPDATE_LINK_CHANGE';
export const SET_NCO_ACTION_OWNER = 'SET_NCO_ACTION_OWNER';
export const SET_RCA_ALONG_WITH_CAPA = 'SET_RCA_ALONG_WITH_CAPA';
export const CLEAR_RCA_ALONG_WITH_CAPA = 'CLEAR_RCA_ALONG_WITH_CAPA';
export const RESET_INITIATION_DATA = 'RESET_INITIATION_DATA';
export const CC_NO_LIST = 'CC_NO_LIST';
export const DOCUMENTS_NO = 'DOCUMENTS_NO';
export const CC_INITIATION_DETAILS = 'CC_INITIATION_DETAILS';
export const CC_FILTER = 'CC_FILTER';
export const NCO_FILTER = 'NCO_FILTER';

export const getDropdownValues = () => (dispatch) => {
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    fetch
      .get('/GetRSKFormDropdown')
      .then(({ data }) => {
        dispatch({ type: SET_DROPDOWN_VALUES, data });
        dispatch({ type: SET_COMMON_ATTRIBUTES, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.cmm) {
    fetch
      .get('/GetCCFormDropdown')
      .then(({ data }) => {
        dispatch({ type: SET_DROPDOWN_VALUES, data });
        dispatch({ type: SET_COMMON_ATTRIBUTES, data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    fetch
      .get('/GetDACFormDropdown')
      .then(({ data }) => {
        dispatch({ type: SET_DROPDOWN_VALUES, data });
        dispatch({ type: SET_COMMON_ATTRIBUTES, data });
      })
      .catch((err) => console.log(err));
    return;
  }

  fetch
    .get('/GetCCFormDropdown')
    .then(({ data }) => {
      dispatch({ type: SET_DROPDOWN_VALUES, data });
      dispatch({ type: SET_COMMON_ATTRIBUTES, data });
    })
    .catch((err) => console.log(err));
};

export const getMtfDropdownValues = () => (dispatch) => {
  fetch
    .get('/GetMTFFormDropdown')
    .then(({ data }) => {
      dispatch({ type: SET_DROPDOWN_VALUES, data });
      dispatch({ type: SET_COMMON_ATTRIBUTES, data });
      dispatch({ type: GET_NEXT_ASSIGNEES, data: data.assignees });
    })
    .catch((err) => console.log(err));
};

export const getNcoDropdownValues = () => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');

    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/GetCAPAFormDropdown/`,
        null,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_NCO_DROPDOWN_VALUES, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const getDeptartmentProjects = (department) => (dispatch) => {
  const module = localStorage.getItem('module');
  const organization = localStorage.getItem('organization');
  const obj = { organization, department };
  if (module === MODULE.nco) {
    fetch
      .post('/GetAllDepartmentProject/', obj)
      .then(({ data }) => {
        dispatch({ type: SET_PROJECT_VALUES, data });
      })
      .catch((err) => {
        dispatch({ type: SET_PROJECT_VALUES, data: ['Test 1', 'Test 2'] });
        console.log(err);
      });
    return;
  } else {
    fetch
      .post('/GetDepartmentProject/', obj)
      .then(({ data }) => {
        dispatch({ type: SET_PROJECT_VALUES, data });
      })
      .catch((err) => {
        dispatch({ type: SET_PROJECT_VALUES, data: ['Test 1', 'Test 2'] });
        console.log(err);
      });
  }
};

export const updateActionItemNotification = (obj) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  obj = { ...obj, organization };
  ncoHttps
    .put(`/ActionItem.php`, obj)
    .then(({ data }) => {
      getInitiationDetails({ nco_no: obj.nco_no, type: 'main' })(dispatch);
      if (obj.action_type != 'revert') {
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_FUNCTIONAL_REVIEW',
            modalProps: { show: false },
          })
        );
      } else {
        message.success('Action Item Reverted Successfully');

        const mailObj = {
          email_type: 'action_item_revert',
          stage: 'Action Item Notification',
          stage_index: 1,
          action_items: [obj.action_id],
          action_by: localStorage.getItem('username'),
          nco_no: obj.nco_no,
        };
        sendEmail(mailObj);
      }
    })
    .error((err) => {
      console.log(err);
    });
};

export const addActionItemNotification = (obj) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  obj = { ...obj, organization };
  ncoHttps
    .post(`/ActionItem.php`, obj)
    .then(({ data }) => {
      getInitiationDetails({ nco_no: obj.nco_no, type: 'main' })(dispatch);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ACTION_ITEM_NOTIFICATION_FORM',
          modalProps: { show: false },
        })
      );
    })
    .error((err) => {
      dispatch(
        hideModal({
          type: 'ACTION_ITEM_NOTIFICATION_FORM',
        })
      );
    });
};

export const deleteActionItem = (payload) => (dispatch) => {
  const created_name = `${localStorage.getItem('name')} (${localStorage.getItem(
    'username'
  )})`;
  const obj = {
    action_id: payload.action_id,
    nco_no: payload.nco_no,
    created_name: created_name,
  };
  ncoHttps
    .delete('/ActionItem.php', { data: obj })
    .then((response) => {
      getInitiationDetails({ nco_no: payload.nco_no, type: 'main' })(dispatch);
    })
    .catch((error) => {
      console.error('Error deleting action item:', error);
    });
};
export const getActionItemNotification = (obj) => (dispatch) => {
  ncoHttps
    .get(`/ActionItem.php?nco_no=${obj}`)
    .then(({ data }) => {
      // console.log(data)
      const res = data;
      dispatch({ type: GET_ACTION_ITEM_NOTIFICATION, data: res });
    })
    .catch((error) => {
      dispatch({ type: GET_ACTION_ITEM_NOTIFICATION, data: [] });
    });
};

export const getDrafts = () => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const module = localStorage.getItem('module');
  if (module === MODULE.mtf) {
    mtfHttps
      .get(`/getDrafts.php`, {
        params: {
          organization,
        },
      })
      .then(({ data }) => {
        const res = data;
        const excelData = formatMTFDraftExcelData(res);
        dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
        // dispatch(getNextAssignee());
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(`/getDrafts.php`, {
        params: {
          organization,
        },
      })
      .then(({ data }) => {
        const res = data;
        const excelData = formatDraftExcelData(res);
        dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
        // dispatch(getNextAssignee());
      });
    return;
  }

  if (module === MODULE.rm) {
    rmHttps
      .get(`/getDrafts.php`, {
        params: {
          organization,
        },
      })
      .then(({ data }) => {
        const res = data;
        const excelData = formatDraftExcelData(res);
        dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
        // dispatch(getNextAssignee());
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .get(`/getDrafts.php`, {
        params: {
          organization,
        },
      })
      .then(({ data }) => {
        const res = data;
        const excelData = formatDraftExcelData(res);
        dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
        // dispatch(getNextAssignee());
      });
    return;
  }

  https
    .get(`/getDrafts.php`, {
      params: {
        organization,
      },
    })
    .then(({ data }) => {
      const res = data;
      const excelData = formatDraftExcelData(res);
      dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
      // dispatch(getNextAssignee());
    });
};
export const ncogetDrafts = () => (dispatch) => {
  const organizations = localStorage.getItem('organization');
  const module = localStorage.getItem('module');
  const formData = {
    organization: localStorage.getItem('organization'),
  };
  ncoHttps.post(`/getDrafts.php`, formData).then(({ data }) => {
    const res = data;
    const excelData = formatDraftExcelData(res);
    dispatch({ type: GET_DRAFT_DATA, data: res, excelData });
    // dispatch(getNextAssignee());
  });
};

export const getMtfInitiations = () => (dispatch) => {
  const organization = localStorage.getItem('organization');

  mtfHttps
    .get(`/getView.php?organization=${organization}`)
    .then(({ data }) => {
      const role = localStorage.getItem('role');
      const res = data.filter((d) => d.mf_stage === role);
      const excelData = formatMTFExcelData('initiation', res);
      dispatch({ type: GET_INITIATION_DATA, data: res, excelData });
      // dispatch(getNextAssignee());
    })
    .catch((err) => console.log(err));
};

export const getNcoInitiations = (type) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const username = localStorage.getItem('username');
  const name = localStorage.getItem('name');
  const role = localStorage.getItem('role');
  if (type == false) {
    ncoHttps
      .get(`/getViewOptimised.php?request_type=my_request`)
      .then(({ data }) => {
        // const role = localStorage.getItem('role');
        // const res = data.filter((d) => d.nco_stage === role);
        const excelData = formatNcoExcelData('initiation', data);
        dispatch({ type: GET_INITIATION_DATA, data: data, excelData });
      })
      .catch((err) => console.log(err));
  } else if (type == true) {
    ncoHttps
      .get(`/getViewOptimised.php?request_type=all_request`)
      .then(({ data }) => {
        const role = localStorage.getItem('role');

        const excelData = formatNcoExcelData('initiation', data);
        dispatch({ type: GET_INITIATION_DATA, data: data, excelData });
      })
      .catch((err) => console.log(err));
  } else if (type) {
    ncoHttps
      .get(`/getViewOptimised.php?nco_no=${type}`)
      .then(({ data }) => {
        const excelData = formatNcoExcelData('initiation', data);
        dispatch({ type: GET_INITIATION_DATA, data: data, excelData });
      })
      .catch((err) => console.log(err));
  }
};

export const getInitiations = () => (dispatch) => {
  const organization = localStorage.getItem('organization');
  // const module = 'NC/Observation And CAPA Module';
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .get(`/getView.php?organization=${organization}`)
      .then(({ data }) => {
        const role = localStorage.getItem('role');
        const res = data.filter((d) => d.r_stage === role);
        const excelData = formatExcelData('initiation', res);
        dispatch({ type: GET_INITIATION_DATA, data: res, excelData });
        dispatch(getNextAssignee());
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(`/getView.php?organization=${organization}`)
      .then(({ data }) => {
        const role = localStorage.getItem('role');
        const res = data.filter((d) => d.d_stage === role);
        const excelData = formatExcelData('initiation', res);
        dispatch({ type: GET_INITIATION_DATA, data: res, excelData });
        dispatch(getNextAssignee());
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .get(`/getView.php?organization=${organization}`)
      .then(({ data }) => {
        const role = localStorage.getItem('role');
        const res = data.filter((d) => d.ini_stage === role);
        const excelData = formatExcelData('initiation', res);
        dispatch({ type: GET_INITIATION_DATA, data: res, excelData });
        dispatch(getNextAssignee());
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .get(`/getView.php?organization=${organization}`)
    .then(({ data }) => {
      const role = localStorage.getItem('role');
      const res = data.filter((d) => d.ini_stage === role);
      const excelData = formatExcelData('initiation', res);
      dispatch({ type: GET_INITIATION_DATA, data: res, excelData });
      dispatch(getNextAssignee());
    })
    .catch((err) => console.log(err));
};

export const getInitiationDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  console.log(obj);
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INITIATION_DETAILS, data: res });
        dispatch({ type: INITIATION_FORM, data: res });
        dispatch(getActivityList(obj));

        // dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INITIATION_DETAILS, data: res });
        dispatch({ type: INITIATION_FORM, data: res });
        dispatch(getActivityList(obj));
        // dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }

  if (formData.id || formData.mf_no) {
    mtfHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INITIATION_DETAILS, data: res });
        dispatch({ type: INITIATION_FORM, data: res });
        dispatch(getActivityList(obj));
        // dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }
  if (formData.cc_no || formData.cc_id) {
    if (module === MODULE.cmm) {
      cmmHttps
        .post('/getInitiation.php', obj)
        .then(({ data }) => {
          const res = data[0];
          dispatch({ type: INITIATION_DETAILS, data: res });
          dispatch({ type: INITIATION_FORM, data: res });
          dispatch(getActivityList(obj));
          dispatch(getNextAssignee());
          dispatch(getAttachmentsList(obj));
        })
        .catch((err) => console.log(err));
      return;
    }
    https
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INITIATION_DETAILS, data: res });
        dispatch({ type: INITIATION_FORM, data: res });
        dispatch(getActivityList(obj));
        dispatch(getNextAssignee());
        dispatch(getAttachmentsList(obj));
      })
      .catch((err) => console.log(err));
    return;
  }
  if (formData.nco_no || formData.nco_id) {
    ncoHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        console.log(data);
        const res = data[0];
        dispatch({ type: INITIATION_DETAILS, data: res });
        dispatch({ type: INITIATION_FORM, data: res });
        dispatch(getActivityList(obj));
        // dispatch(getActivityCapaImp(obj));
        // dispatch(getNextAssignee());
        dispatch(getNCOAttachmentsList(formData.nco_no));
      })
      .catch((err) => console.log(err));
    return;
  }
  // dispatch(getNextAssignee());
};

export const getCCInitiationDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  console.log(obj);
  const module = localStorage.getItem('module');

  if (formData.nco_no || formData.nco_id) {
    ncoHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        console.log(data);
        const res = data[0];
        dispatch({ type: CC_INITIATION_DETAILS, data: res });
        dispatch(getActivityList(obj));
        // dispatch(getActivityCapaImp(obj));
        // dispatch(getNextAssignee());
        dispatch(getNCOAttachmentsList(formData.nco_no));
      })
      .catch((err) => console.log(err));
    return;
  }
  // dispatch(getNextAssignee());
};

export const deleteDraftRow = (id) => (dispatch) => {
  const role = localStorage.getItem('role');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/deleteDraft.php', {
        role,
        r_id: id,
      })
      .then(({ data }) => {
        dispatch({ type: REMOVE_DRAFT_ROW, data: id });
      })
      .catch((err) => {
        message.error('Draft not Deleted.');
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/deleteDraft.php', {
        role,
        d_id: id,
      })
      .then(({ data }) => {
        dispatch({ type: REMOVE_DRAFT_ROW, data: id });
      })
      .catch((err) => {
        message.error('Draft not Deleted.');
      });
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/deleteDraft.php', {
        role,
        id,
      })
      .then(({ data }) => {
        dispatch({ type: REMOVE_DRAFT_ROW, data: id });
      })
      .catch((err) => {
        message.error('Draft not Deleted.');
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteDraft.php', {
        role,
        cc_id: id,
      })
      .then(({ data }) => {
        dispatch({ type: REMOVE_DRAFT_ROW, data: id });
      })
      .catch((err) => {
        message.error('Draft not Deleted.');
      });
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/deleteDraft.php', {
        role,
        nco_id: id,
      })
      .then(({ data }) => {
        dispatch({ type: REMOVE_DRAFT_ROW, data: id });
      })
      .catch((err) => {
        message.error('Draft not Deleted.');
      });
    return;
  }
  https
    .post('/deleteDraft.php', {
      role,
      cc_id: id,
    })
    .then(({ data }) => {
      dispatch({ type: REMOVE_DRAFT_ROW, data: id });
    })
    .catch((err) => {
      message.error('Draft not Deleted.');
    });
};
export const getNcoActionOwner = (formData) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const dataType = {
      project: formData,
    };
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/getActionOwner/`,
        dataType,
        config
      )
      .then(({ data }) => {
        dispatch({ type: SET_NCO_ACTION_OWNER, data });
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};
export const getIniDetails = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INI_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: INI_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INI_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: INI_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INI_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: INI_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getInitiation.php', obj)
      .then(({ data }) => {
        const res = data[0];
        dispatch({ type: INI_MODAL_DETAILS, data: res });
        dispatch(getActivityList(obj));
      })
      .catch((err) => {
        dispatch({ type: INI_DETAILS_ERR, error: true });
        console.log(err);
      });
    return;
  }

  https
    .post('/getInitiation.php', obj)
    .then(({ data }) => {
      const res = data[0];
      dispatch({ type: INI_MODAL_DETAILS, data: res });
      dispatch(getActivityList(obj));
    })
    .catch((err) => {
      dispatch({ type: INI_DETAILS_ERR, error: true });
      console.log(err);
    });
};

export const getActivityList = (obj) => (dispatch) => {
  const module = localStorage.getItem('module');
  if (module === MODULE.rm) {
    rmHttps
      .post('/getActivity.php', obj)
      .then(({ data }) => {
        dispatch({ type: ACTIVITY_LIST, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/getActivity.php', obj)
      .then(({ data }) => {
        dispatch({ type: ACTIVITY_LIST, data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/getActivity.php', obj)
      .then(({ data }) => {
        dispatch({ type: ACTIVITY_LIST, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getActivity.php', obj)
      .then(({ data }) => {
        dispatch({ type: ACTIVITY_LIST, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.nco) {
    ncoHttps
      .post('/getActivity.php', obj)
      .then(({ data }) => {
        dispatch({ type: ACTIVITY_LIST, data });
      })
      .catch((err) => console.log(err));
    return;
  }
  https
    .post('/getActivity.php', obj)
    .then(({ data }) => {
      dispatch({ type: ACTIVITY_LIST, data });
    })
    .catch((err) => console.log(err));
};

const getMemoToFileNumber = async (formBody) => {
  const obj = {
    project: formBody.mf_project_name,
    department: formBody.mf_department,
  };
  const { data } = await fetch.post('/GetMTFSequence/', obj);

  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getChangeControlNumber = async (formBody) => {
  console.log(formBody);
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.cci_department,
    project: formBody.cci_project_name,
  };

  const { data } = await fetch.post('/generateCCnumber/', obj);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getRiskNumber = async (formBody) => {
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.r_department,
    project: formBody.r_project_name,
  };

  const { data } = await fetch.post('/generateRiskRegisterNumber/', obj);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getDeviationNumber = async (formBody) => {
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.d_department,
    project: formBody.d_project_name,
  };

  const { data } = await fetch.post('/generateDeviationNumber/', obj);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getImplementationNumber = async (formBody) => {
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.cci_department,
    project: formBody.cci_project_name,
  };

  const { data } = await fetch.post('/generateCCInumber/', obj);
  console.log('sequence_no', data);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getCapaNumber = async (formBody) => {
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.d_department,
    project: formBody.d_project_name,
  };

  const { data } = await fetch.post('/generateCapaNumber/', obj);
  console.log('sequence_no', data);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

const getMitigationNumber = async (formBody) => {
  const organization = localStorage.getItem('organization');
  const obj = {
    organization: organization,
    department: formBody.r_department,
    project: formBody.r_project_name,
  };

  const { data } = await fetch.post('/generateRiskMitigationNumber/', obj);
  console.log('sequence_no', data);
  if (data.hasOwnProperty('error')) {
    throw new Error(data.error);
  }
  return data.sequence_no;
};

export const saveAsDraft = (formBody, history) => (dispatch) => {
  const module = localStorage.getItem('module');
  dispatch({ type: SET_LOADER, data: true });
  if (module === MODULE.rm) {
    rmHttps
      .post('/createNewRequest.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/rm/initiation');
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/createNewRequest.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/dnc/initiation');
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
      .post('/createNewRequest.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/mtf/initiation');
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/createNewRequest.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/iControl/initiation');
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }
  https
    .post('/createNewRequest.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      history.push('/iControl/initiation');
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const ncoSaveAsDraft = (formBody, history) => (dispatch) => {
  const module = localStorage.getItem('module');
  dispatch({ type: SET_LOADER, data: true });

  ncoHttps
    .post('/createNewRequest.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      history.push('/nco/initiation');
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const saveMTF = (formBody) => (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  mtfHttps
    .post('/save.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      dispatch({ type: CLEAR_ATTACHMENTS });
      await dispatch(getAttachmentsList({ mf_no: formBody.mf_no }));
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const forwardToApprover = (formBody, history) => (dispatch) => {
  // console.log(formBody);
  const obj = {
    ...formBody,
    attachment_role: 'qa_reviewer',
    flow: 'capa_plan',
  };

  const mailObj = {
    email_type: 'stage_complete',
    stage: 'CAPA Plan Verification',
    stage_index: 1,
    action_by: formBody.username,
    assigned_to: formBody.current_handler,
    assigned_comment: formBody.activity_comments,
    nco_no: formBody.nco_no,
  };

  ncoHttps
    .post('/verifertoapprover.php', JSON.stringify(obj), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Case Moved to Approver Successful',
        key: 'updatable',
      });
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );

      history.push('/nco/verification');
      dispatch({ type: SET_LOADER, data: false });
      dispatch({ type: CLEAR_ATTACHMENTS });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveNCO = (formBody) => (dispatch) => {
  console.log(formBody);
  const role = localStorage.getItem('role');
  dispatch({ type: SET_LOADER, data: true });
  const formData = {
    ...formBody,
    attachment_role: role,
    flow: 'capa_plan',
  };
  ncoHttps
    .post('/save.php', JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      dispatch({ type: CLEAR_ATTACHMENTS });
      await dispatch(
        getInitiationDetails({ nco_no: formBody.nco_no, type: 'main' })
      );
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const saveCC = (formBody) => (dispatch) => {
  const module = localStorage.getItem('module');
  dispatch({ type: SET_LOADER, data: true });

  if (module === MODULE.rm) {
    rmHttps
      .post('/save.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(
          getInitiationDetails({ risk_id: formBody.risk_id, type: 'main' })
        );
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });

    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/save.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(
          getInitiationDetails({ d_no: formBody.d_no, type: 'main' })
        );
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });

    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/save.php', JSON.stringify(formBody), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async () => {
        message.success({
          content: 'Form saved Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        dispatch({ type: CLEAR_ATTACHMENTS });
        await dispatch(
          getInitiationDetails({ cc_no: formBody.cc_no, type: 'main' })
        );
      })
      .catch((err) => {
        message.error('Error saving! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }

  https
    .post('/save.php', JSON.stringify(formBody), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async () => {
      message.success({
        content: 'Form saved Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      dispatch({ type: CLEAR_ATTACHMENTS });
      await dispatch(
        getInitiationDetails({ cc_no: formBody.cc_no, type: 'main' })
      );
    })
    .catch((err) => {
      message.error('Error saving! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const mtfInitiate = (formBody, history) => async (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  try {
    const mf_no = await getMemoToFileNumber(formBody);
    formBody['mf_no'] = mf_no;
  } catch (err) {
    message.error(err.message);
    dispatch({ type: SET_LOADER, data: false });
    return;
  }

  const obj = JSON.stringify(formBody);
  try {
    await audit(formBody);
  } catch (err) {
    message.error('Error Submitting! Try again.');
    dispatch({ type: SET_LOADER, data: false });
    return;
  }
  const mailObj = {
    email_type: 'new',
    stage: 'Memo To File Initiation',
    stage_index: 0,
    action_by: formBody.username,
    mf_no: formBody.mf_no,
    username: formBody.username,
  };

  mtfHttps
    .post('/initiate.php', obj)
    .then(() => {
      sendEmail(mailObj);
      // callback();
      message.success({
        content: 'Form Submission Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      history.push('/mtf/initiation');
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

export const ccInitiate = (formBody, history) => async (dispatch) => {
  // console.log(  formBody)
  const module = localStorage.getItem('module');
  dispatch({ type: SET_LOADER, data: true });
  if (module === MODULE.rm) {
    try {
      const risk_id = await getRiskNumber(formBody);
      formBody['risk_id'] = risk_id;
    } catch (err) {
      message.error(err.message);
      return;
    }

    const obj = JSON.stringify(formBody);
    try {
      await audit({ ...formBody, stage: 'Risk Registration' });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailObj = {
      email_type: 'new',
      stage: 'Risk Initiation',
      stage_index: 0,
      action_by: formBody.username,
      risk_id: formBody.risk_id,
    };

    rmHttps
      .post('/initiate.php', obj)
      .then(() => {
        sendEmail(mailObj);
        // callback();
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/rm/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });

    return;
  }
  if (module === MODULE.dnc) {
    try {
      const d_no = await getDeviationNumber(formBody);
      formBody['d_no'] = d_no;
    } catch (err) {
      message.error(err.message);
      return;
    }

    const obj = JSON.stringify(formBody);
    try {
      await audit({ ...formBody, stage: 'Deviation Initiation' });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailObj = {
      email_type: 'new',
      stage: 'Deviation Initiation',
      stage_index: 0,
      action_by: formBody.username,
      d_no: formBody.d_no,
    };

    dncHttps
      .post('/initiate.php', obj)
      .then(() => {
        sendEmail(mailObj);
        // callback();
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/dnc/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });

    return;
  }

  try {
    const cc_no = await getChangeControlNumber(formBody);
    formBody['cc_no'] = cc_no;
  } catch (err) {
    message.error(err.message);
    return;
  }

  const obj = JSON.stringify(formBody);
  try {
    await audit({ ...formBody, stage: 'Change Control Initiation' });
  } catch (err) {
    message.error('Error Submitting! Try again.');
    dispatch({ type: SET_LOADER, data: false });
    return;
  }

  const mailObj = {
    email_type: 'new',
    stage: 'Change Control Initiation',
    stage_index: 0,
    action_by: formBody.username,
    cc_no: formBody.cc_no,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/initiate.php', obj)
      .then(() => {
        sendEmail(mailObj);
        // callback();
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        dispatch({ type: SET_LOADER, data: false });
        history.push('/iControl/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
    return;
  }

  https
    .post('/initiate.php', obj)
    .then(() => {
      sendEmail(mailObj);
      // callback();
      message.success({
        content: 'Form Submission Successful',
        key: 'updatable',
      });
      dispatch({ type: SET_LOADER, data: false });
      history.push('/iControl/initiation');
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      console.log(err);
    });
};

function generateRandomString() {
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var numbers = '0123456789';
  var randomString = '';

  // Generate 3 random characters
  for (var i = 0; i < 3; i++) {
    var randomChar = characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
    randomString += randomChar;
  }

  // Generate 3 random numbers
  for (var j = 0; j < 3; j++) {
    var randomNumber = numbers.charAt(
      Math.floor(Math.random() * numbers.length)
    );
    randomString += randomNumber;
  }

  return randomString;
}

const getNcoSequenceNo = async (formDepartment, formProject) => {
  try {
    let userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    const formData = {
      organization: localStorage.getItem('organization'),
      department: formDepartment,
      project: formProject,
    };
    console.log(JSON.stringify(formData));
    const response = await axios.post(
      `${process.env.REACT_APP_IACCESS_URL}/generateCAPAnumber/`,
      JSON.stringify(formData),
      config
    );
    console.log('err0');
    return response.data['sequence_no'];
  } catch (err) {
    console.log('err');
    throw new Error(err.message);
  }
};

export const initiateNco =
  (formBody, history, callback) => async (dispatch) => {
    console.log('form body', formBody);
    const role = localStorage.getItem('role');

    const cce_status = formBody.cce_status;
    const obj = JSON.stringify({
      ...formBody,
      attachment_role: role,
      flow: 'capa_plan',
      req_type: cce_status === 'route_back' ? 'update' : 'new',
    });

    try {
      await audit({ ...formBody, stage: 'CAPA Plan Initiation' });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailObj = {
      email_type: 'stage_complete',
      stage: 'CAPA Plan Initiation',
      stage_index: 0,
      action_by: formBody.username,
      assigned_to: formBody.current_handler,
      assigned_comment: formBody.activity_comments,
      nco_no: formBody.nco_no,
    };

    ncoHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        dispatch({ type: RESET_INITIATION_DATA });
        history.push('/nco/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });
  };

export const initiate =
  (formBody, history, callback, attachment_role, flow) => async (dispatch) => {
    console.log('form body', formBody);

    console.log('flow', flow);
    const module = localStorage.getItem('module');
    if (module === MODULE.rm) {
      const re_status = formBody.re_status;
      const obj = JSON.stringify({
        ...formBody,
        req_type: re_status === 'route_back' ? 'update' : 'new',
      });

      try {
        await audit({ ...formBody, stage: 'Risk Registration' });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        return;
      }

      const mailObj = {
        email_type: 'stage_complete',
        stage: 'Risk Registration',
        stage_index: 0,
        action_by: formBody.username,
        assigned_to: formBody.pr_asignee,
        assigned_comment: formBody.activity_comments,
        risk_id: formBody.risk_id,
      };

      rmHttps
        .post('/newInitiation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          callback();
          message.success({
            content: 'Form Submission Successful',
            key: 'updatable',
          });
          history.push('/rm/initiation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }
    if (module === MODULE.dnc) {
      const de_status = formBody.de_status;
      const obj = JSON.stringify({
        ...formBody,
        req_type: de_status === 'route_back' ? 'update' : 'new',
        audit_stage: 'CAPA Plan Initiation',
      });

      try {
        await audit({ ...formBody, stage: 'Deviation Initiation' });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        return;
      }

      const mailObj = {
        email_type: 'stage_complete',
        stage: 'Deviation Initiation',
        stage_index: 0,
        action_by: formBody.username,
        assigned_to: formBody.pr_asignee,
        assigned_comment: formBody.activity_comments,
        d_no: formBody.d_no,
      };

      dncHttps
        .post('/newInitiation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          callback();
          message.success({
            content: 'Form Submission Successful',
            key: 'updatable',
          });
          history.push('/dnc/initiation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    if (module === MODULE.nco) {
      const de_status = formBody.de_status;
      const obj = JSON.stringify({
        role: 'initiator',
        ...formBody,
        req_type: de_status === 'route_back' ? 'update' : 'new',
      });

      try {
        await audit({ ...formBody, stage: 'Deviation Initiation' });
      } catch (err) {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        return;
      }

      const mailObj = {
        email_type: 'stage_complete',
        stage: 'Initiation',
        stage_index: 0,
        action_by: formBody.username,
        assigned_to: formBody.qr_asignee,
        assigned_comment: formBody.activity_comments,
        nco_no: formBody.nco_no,
      };

      ncoHttps
        .post('/newInitiation.php', obj, flow, attachment_role)
        .then(() => {
          sendEmail(mailObj);
          callback();
          message.success({
            content: 'Form Submission Successful',
            key: 'updatable',
          });
          console.log(obj);
          history.push('/nco/initiation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    const cce_status = formBody.cce_status;
    const obj = JSON.stringify({
      ...formBody,
      req_type: cce_status === 'route_back' ? 'update' : 'new',
    });

    try {
      await audit({ ...formBody, stage: 'Change Control Initiation' });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailObj = {
      email_type: 'stage_complete',
      stage: 'Change Control Initiation',
      stage_index: 0,
      action_by: formBody.username,
      assigned_to: formBody.pr_asignee,
      assigned_comment: formBody.activity_comments,
      cc_no: formBody.cc_no,
    };

    if (module === MODULE.cmm) {
      cmmHttps
        .post('/newInitiation.php', obj)
        .then(() => {
          sendEmail(mailObj);
          callback();
          message.success({
            content: 'Form Submission Successful',
            key: 'updatable',
          });
          history.push('/iControl/initiation');
        })
        .catch((err) => {
          message.error('Error Submitting! Try again.');
          console.log(err);
        });
      return;
    }

    https
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        history.push('/iControl/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });
  };

export const evaluate = (formBody, history, callback) => async (dispatch) => {
  const module = localStorage.getItem('module');
  const assignee = ini_assignee_alias[formBody.role];

  if (module === MODULE.rm) {
    if (formBody.ra_status === 'mitigate_risk') {
      console.log({ forCapa: formBody });
      try {
        const rc_id = await getMitigationNumber(formBody);
        formBody['rc_id'] = rc_id;
      } catch (err) {
        callback();
        message.error(err.message);
        return;
      }
    }

    try {
      await audit({ ...formBody, stage: rm_ini_stage_alias[formBody.role] });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailData = {
      email_type:
        formBody.role === 'qa_approver' ? formBody.da_status : 'stage_complete',
      stage: rm_ini_stage_alias[formBody.role],
      stage_index: ini_mail_stage[formBody.role],
      action_by: formBody.username,
      risk_id: formBody.risk_id,
    };

    const mailObj =
      formBody.role !== 'qa_approver'
        ? {
            ...mailData,
            assigned_to: formBody[assignee],
            assigned_comment: formBody.activity_comments,
          }
        : mailData;

    const obj = JSON.stringify(formBody);

    rmHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        history.push('/rm/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });

    return;
  }

  if (module === MODULE.dnc) {
    if (formBody.da_status === 'pending_for_capa') {
      try {
        const ca_no = await getCapaNumber(formBody);
        formBody['ca_no'] = ca_no;
      } catch (err) {
        callback();
        message.error(err.message);
        return;
      }
    }

    try {
      await audit({ ...formBody, stage: dnc_ini_stage_alias[formBody.role] });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailData = {
      email_type:
        formBody.role === 'qa_approver' ? formBody.da_status : 'stage_complete',
      stage: dnc_ini_stage_alias[formBody.role],
      stage_index: ini_mail_stage[formBody.role],
      action_by: formBody.username,
      d_no: formBody.d_no,
    };

    const mailObj =
      formBody.role !== 'qa_approver'
        ? {
            ...mailData,
            assigned_to: formBody[assignee],
            assigned_comment: formBody.activity_comments,
          }
        : mailData;

    const obj = JSON.stringify(formBody);

    dncHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        history.push('/dnc/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });

    return;
  }

  if (module === MODULE.nco) {
    console.log(formBody);
    if (formBody.da_status === 'pending_for_capa') {
      try {
        const ca_no = generateRandomString();
        // const ca_no = await getCapaNumber(formBody);
        formBody['ca_no'] = ca_no;
      } catch (err) {
        callback();
        message.error(err.message);
        return;
      }
    }

    const obj = JSON.stringify(formBody);
    const mailObj = {
      email_type: 'capa_second_approval',
      stage: 'CAPA Plan Approval',
      stage_index: 3,
      assigned_to: formBody.qa_second_asignee,
      action_by: formBody.username,
      assigned_comment: formBody.activity_comments,
      nco_no: formBody.nco_no,
    };

    const mailObj2 = {
      email_type: 'capa_approve',
      stage: 'CAPA Plan Approval',
      stage_index: 3,
      action_by: formBody.username,
      assigned_comment: formBody.activity_comments,
      nco_no: formBody.nco_no,
    };

    ncoHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        if (formBody.approver_type === 'secondInitiation') {
          sendEmail(mailObj);
        } else {
          sendEmail(mailObj2);
        }
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        history.push('/nco/approval');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });

    return;
  }
  if (module === MODULE.cmm) {
    try {
      await audit({ ...formBody, stage: ini_stage_alias[formBody.role] });
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    const mailData = {
      email_type:
        formBody.role === 'qa_approver'
          ? formBody.cca_status
          : 'stage_complete',
      stage: ini_stage_alias[formBody.role],
      stage_index: ini_mail_stage[formBody.role],
      action_by: formBody.username,
      cc_no: formBody.cc_no,
    };

    const mailObj =
      formBody.role !== 'qa_approver'
        ? {
            ...mailData,
            assigned_to: formBody[assignee],
            assigned_comment: formBody.activity_comments,
          }
        : mailData;

    const obj = JSON.stringify(formBody);
    cmmHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        history.push('/iControl/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });
    return;
  }

  if (formBody.cca_status === 'approve') {
    try {
      const imp_no = await getImplementationNumber(formBody);
      formBody['imp_no'] = imp_no;
    } catch (err) {
      callback();
      message.error(err.message);
      return;
    }
  }

  try {
    await audit({ ...formBody, stage: ini_stage_alias[formBody.role] });
  } catch (err) {
    message.error('Error Submitting! Try again.');
    dispatch({ type: SET_LOADER, data: false });
    return;
  }

  const mailData = {
    email_type:
      formBody.role === 'qa_approver' ? formBody.cca_status : 'stage_complete',
    stage: ini_stage_alias[formBody.role],
    stage_index: ini_mail_stage[formBody.role],
    action_by: formBody.username,
    cc_no: formBody.cc_no,
  };

  const mailObj =
    formBody.role !== 'qa_approver'
      ? {
          ...mailData,
          assigned_to: formBody[assignee],
          assigned_comment: formBody.activity_comments,
        }
      : mailData;

  const obj = JSON.stringify(formBody);

  https
    .post('/newInitiation.php', obj)
    .then(() => {
      sendEmail(mailObj);
      message.success({
        content: 'Form Submission Successful',
        key: 'updatable',
      });
      callback();
      history.push('/iControl/initiation');
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const mtfEvaluate =
  (formBody, history, callback) => async (dispatch) => {
    const obj = JSON.stringify(formBody);
    const stage_index = mtf_mail_stage[formBody.role];
    dispatch({ type: SET_LOADER, data: true });

    const approveMail = {
      email_type: formBody.a_status,
      stage: mf_stage_alias[formBody.role],
      stage_index,
      action_by: formBody.username,
      mf_no: formBody.mf_no,
    };
    const mailData = {
      email_type: 'activity_completion',
      stage: mf_stage_alias[formBody.role],
      stage_index,
      action_by: formBody.username,
      mf_no: formBody.mf_no,
    };

    if (formBody.role === 'reviewer') {
      mailData.assigned_to = formBody.a_asignee;
      mailData.assigned_comment = formBody.activity_comments;
    }

    try {
      await audit(formBody);
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }

    await mtfHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailData);
        if (stage_index === 2) {
          sendEmail(approveMail);
        }
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        dispatch({ type: SET_LOADER, data: false });
        history.push('/mtf/initiation');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
  };

export const secondApproval =
  (formBody, history, callback) => async (dispatch) => {
    const module = localStorage.getItem('module');
    const assignee = ini_assignee_alias[formBody.role];

    const obj = JSON.stringify(formBody);
    console.log(formBody);
    ncoHttps
      .post('/secondApproval.php', obj)
      .then(() => {
        // sendEmail(mailObj);
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        dispatch({ type: RESET_INITIATION_DATA });

        //  callback();
        // history.push('/nco/approval');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });

    return;
  };

export const forwardToVerifier =
  (formBody, history, callback, flow, attachment_role) => async (dispatch) => {
    const module = localStorage.getItem('module');
    const assignee = ini_assignee_alias[formBody.role];
    const flow = 'capa_plan';
    const attachment_role = 'qa_approver';
    console.log(formBody);
    if (formBody.da_status === 'pending_for_capa') {
      try {
        const ca_no = generateRandomString();
        // const ca_no = await getCapaNumber(formBody);
        formBody['ca_no'] = ca_no;
      } catch (err) {
        callback();
        message.error(err.message);
        return;
      }
    }
    const capa_no_list = formBody.ca_action_items.map((item) => item.capa_no);
    console.log(capa_no_list);

    const obj = {
      ...formBody,
      flow,
      attachment_role,
      audit_stage: 'CAPA Plan Verification',
    };

    const mailObj = {
      email_type: 'capa_approve_implementation',
      stage: 'CAPA Plan Approval',
      stage_index: 3,
      action_by: formBody.username,
      assigned_comment: formBody.activity_comments,
      capa_items: capa_no_list,
      nco_no: formBody.nco_no,
    };
    console.log(mailObj);
    ncoHttps
      .post('/capatoverifier.php', obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Form Submission Successful',
          key: 'updatable',
        });
        callback();
        history.push('/nco/approval');
      })
      .catch((err) => {
        message.error('Error Submitting! Try again.');
        console.log(err);
      });

    return;
  };

export const routeBack = (formbody, history, callback) => async (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formbody);
  console.log(obj);
  const comments = ini_route_back_alias[formbody.role];

  if (module === MODULE.nco) {
    delete formbody.cc_no;
    const comment = formbody.role === 'qa_reviewer' ? 'dr_rbc' : 'da_rbc';
    const mailObj = {
      email_type: 'route_back',
      stage: nco_stage_alias[formbody.role],
      stage_index: nco_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[comment],
      nco_no: formbody.nco_no,
    };
    await ncoHttps
      .post('/routeBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/nco/verification');
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.rm) {
    delete formbody.cc_no;
    const commenta = formbody.role === 'reviewer' ? 'r_rbc' : 'a_rbc';
    const mailObj = {
      email_type: 'route_back',
      stage: rm_ini_stage_alias[formbody.role],
      stage_index: ini_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[commenta],
      risk_id: formbody.risk_id,
    };
    console.log({ routeBackEvaluator: obj });
    await rmHttps
      .post('/routeBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/rm/initiation');
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    delete formbody.cc_no;
    const commenta = formbody.role === 'reviewer' ? 'r_rbc' : 'a_rbc';
    const mailObj = {
      email_type: 'route_back',
      stage: dnc_ini_stage_alias[formbody.role],
      stage_index: ini_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[commenta],
      d_no: formbody.d_no,
    };
    console.log({ routeBackEvaluator: obj });
    await dncHttps
      .post('/routeBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/dnc/initiation');
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.mtf) {
    delete formbody.cc_no;
    const commenta = formbody.role === 'reviewer' ? 'r_rbc' : 'a_rbc';
    const mailObj = {
      email_type: 'route_back',
      stage: mf_stage_alias[formbody.role],
      stage_index: mtf_mail_stage[formbody.role],
      action_by: formbody.username,
      route_comment: formbody[commenta],
      mf_no: formbody.mf_no,
    };
    await mtfHttps
      .post('/routeBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/mtf/initiation');
      })
      .catch((err) => console.log(err));
    return;
  }

  const mailObj = {
    email_type: 'route_back',
    stage: ini_stage_alias[formbody.role],
    stage_index: ini_mail_stage[formbody.role],
    action_by: formbody.username,
    route_comment: formbody[comments],
    cc_no: formbody.cc_no,
  };

  if (module === MODULE.cmm) {
    await cmmHttps
      .post('/routeBack.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        history.push('/iControl/initiation');
      })
      .catch((err) => console.log(err));
    return;
  }

  await https
    .post('/routeBack.php', obj)
    .then(() => {
      sendEmail(mailObj);
      callback();
      history.push('/iControl/initiation');
    })
    .catch((err) => console.log(err));
};

export const fetchRCADetails = (formbody) => (dispatch) => {
  ncoHttps
    .get(`/getAllCapa.php`, {
      params: {
        nco_no: formbody.nco_no,
        action_id: formbody.action_id,
        rca_no: formbody.rca_no,
      },
    })
    .then((res) => {
      dispatch({
        type: 'SET_RCA_ALONG_WITH_CAPA',
        data: res.data,
      });
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};
export const submitInitiation =
  (formBody, history, callback) => async (dispatch) => {
    const r_status = formBody.r_status;
    dispatch({ type: SET_LOADER, data: true });
    const obj = JSON.stringify({
      ...formBody,
      req_type: r_status === 'route_back' ? 'update' : 'new',
    });
    const mailObj = {
      email_type: 'activity_completion',
      stage: 'Memo To File Initiation',
      stage_index: 0,
      action_by: formBody.username,
      assigned_to: formBody.r_asignee,
      assigned_comment: formBody.comments,
      mf_no: formBody.mf_no,
    };
    delete formBody.r_status;
    try {
      await audit(formBody);
    } catch (err) {
      message.error('Error Submitting! Try again.');
      dispatch({ type: SET_LOADER, data: false });
      return;
    }
    await mtfHttps
      .post('/newInitiation.php', obj)
      .then(() => {
        sendEmail(mailObj);
        callback();
        dispatch({ type: SET_LOADER, data: false });
        history.push('/mtf/initiation');
      })
      .catch((err) => {
        dispatch({ type: SET_LOADER, data: false });
        console.log(err);
      });
  };

export const audit = async (formBody) => {
  const module = localStorage.getItem('module');

  const type = formBody.type;
  console.log({ type });
  console.log(module);
  const remove = ['attachments'];
  remove.forEach((_) => {
    delete formBody[_];
  });
  if (module === MODULE.rm) {
    await rmHttps.post('/Audit.php', formBody);
    return;
  }
  if (module === MODULE.nco) {
    return;
  }

  if (module === MODULE.dnc) {
    await dncHttps.post('/Audit.php', formBody);
    return;
  }
  if (module === MODULE.cmm) {
    await cmmHttps.post('/Audit.php', formBody);
    console.log('ic');

    return;
  }
  if (module !== MODULE.mtf) {
    await https.post('/Audit.php', formBody);
    return;
  }
  await mtfHttps.post('/Audit.php', formBody);
};

export const saveInitiation = (formBody, history, callback) => () => {
  const obj = JSON.stringify(formBody);
  mtfHttps
    .post('/save.php', obj)
    .then(() => {
      callback();
      history.push('/mtf/initiation');
    })
    .catch((err) => console.log(err));
};

export const editIntiation = (formbody, history, callback) => () => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formbody);

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/editInitiation.php', obj)
      .then(() => {
        callback();
        history.push('/iControl/initiation');
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/editInitiation.php', obj)
    .then(() => {
      callback();
      history.push('/iControl/initiation');
    })
    .catch((err) => console.log(err));
};

export const updateAssociateChange = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    const obj = JSON.stringify({ associated_risk: [formData] });
    rmHttps
      .post('/updateAssociatedChangeLinkedList.php', obj)
      .then(() => {
        dispatch({
          type: UPDATE_ASSOCIATE_CHANGE,
          data: formData,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_ASSOCIATE_CHANGE',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    return;
  }

  if (module === MODULE.dnc) {
    const obj = JSON.stringify({ deviation_details: [formData] });
    dncHttps
      .post('/updateAssociatedChangeLinkedList.php', obj)
      .then(() => {
        dispatch({
          type: UPDATE_ASSOCIATE_CHANGE,
          data: formData,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_ASSOCIATE_CHANGE',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    return;
  }

  const obj = JSON.stringify({ associated_change_list: [formData] });

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/updateAssociatedChangeLinkedList.php', obj)
      .then(() => {
        dispatch({
          type: UPDATE_ASSOCIATE_CHANGE,
          data: formData,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_ASSOCIATE_CHANGE2',
            modalProps: { show: false },
          })
        );
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_ASSOCIATE_CHANGE',
            modalProps: { show: false },
          })
        );

        dispatch(
          getInitiationDetails({
            cc_no: formData.cc_no,
            type: 'main',
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  https
    .post('/updateAssociatedChangeLinkedList.php', obj)
    .then(() => {
      dispatch({
        type: UPDATE_ASSOCIATE_CHANGE,
        data: formData,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'EDIT_ASSOCIATE_CHANGE',
          modalProps: { show: false },
        })
      );
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'EDIT_ASSOCIATE_CHANGE2',
          modalProps: { show: false },
        })
      );

      dispatch(
        getInitiationDetails({
          cc_no: formData.cc_no,
          type: 'main',
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateAssociateChangeNco = (formData) => (dispatch) => {
  const obj = JSON.stringify({ associated_change_list: [formData] });

  ncoHttps
    .post('/updateAssociatedChangeLinkedList.php', obj)
    .then(() => {
      dispatch({
        type: UPDATE_ASSOCIATE_CHANGE,
        data: formData,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'EDIT_ASSOCIATE_CHANGE',
          modalProps: { show: false },
        })
      );
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
export const updateProductChangeNco = (formData) => (dispatch) => {
  const role = localStorage.getItem('role');
  const role_data = {
    initiator: 'CAPA Plan Initiation',
    qa_reviewer: 'CAPA Plan Verification',
  };

  const obj = JSON.stringify({
    product_details: [formData],
    audit_stage: role_data[role],
  });

  ncoHttps
    .post('/updateAssociatedChangeLinkedList.php', obj)
    .then(() => {
      dispatch({
        type: UPDATE_PRODUCT_CHANGE,
        data: formData,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'EDIT_PRODUCT_CHANGE',
          modalProps: { show: false },
        })
      );
      message.success('Product Updated successfully.');
    })
    .catch((err) => {
      message.error(
        'Product update failed. An error occurred during the process.'
      );
      console.log(err);
    });
};

export const deleteAssociateChange = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .post('/deleteChangeAssociatedList.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ASSOCIATE_CHANGE,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/deleteChangeAssociatedList.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ASSOCIATE_CHANGE,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteChangeAssociatedList.php', obj)
      .then((_) => {
        dispatch({
          type: DELETE_ASSOCIATE_CHANGE,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  }

  https
    .post('/deleteChangeAssociatedList.php', obj)
    .then((_) => {
      dispatch({
        type: DELETE_ASSOCIATE_CHANGE,
        id: formData.id,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteProductChange = (formData) => (dispatch) => {
  // const obj = JSON.stringify(formData);
  const module = localStorage.getItem('module');
  console.log(formData.prod_id);

  ncoHttps
    .post(`/deleteProductDetails.php?prod_id=${formData.prod_id}`)
    .then((_) => {
      dispatch({
        type: DELETE_PRODUCT_CHANGE,
        id: formData.prod_id,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editLinkRequest =
  (formData, isInitiator = true) =>
  (dispatch) => {
    const module = localStorage.getItem('module');
    const type = isInitiator ? EDIT_LINK_CHANGE_REQUEST : UPDATE_LINK_REQUEST;
    const obj = JSON.stringify(formData);

    if (module === MODULE.rm) {
      rmHttps
        .post('/updateLinkChangeRequest.php', obj)
        .then((_) => {
          dispatch({
            type,
            data: formData,
          });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'EDIT_LINK_CHANGE',
              modalProps: { show: false },
            })
          );
        })
        .catch((err) => {
          console.log(err);
          message.error('Update Failed! Try again.');
        });
      return;
    }

    if (module === MODULE.dnc) {
      dncHttps
        .post('/updateLinkChangeRequest.php', obj)
        .then((_) => {
          dispatch({
            type,
            data: formData,
          });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'EDIT_LINK_CHANGE',
              modalProps: { show: false },
            })
          );
        })
        .catch((err) => {
          console.log(err);
          message.error('Update Failed! Try again.');
        });
      return;
    }

    if (module === MODULE.cmm) {
      cmmHttps
        .post('/updateLinkChangeRequest.php', obj)
        .then((_) => {
          dispatch({
            type,
            data: formData,
          });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'EDIT_LINK_CHANGE',
              modalProps: { show: false },
            })
          );
        })
        .catch((err) => {
          console.log(err);
          message.error('Update Failed! Try again.');
        });
      return;
    }

    https
      .post('/updateLinkChangeRequest.php', obj)
      .then((_) => {
        dispatch({
          type,
          data: formData,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'EDIT_LINK_CHANGE',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
        message.error('Update Failed! Try again.');
      });
  };

export const updateLinkRequest = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');
  const type =
    formData.type === 'ini' ? UPDATE_LINK_REQUEST : UPDATE_LINK_REQUEST;
  const obj = JSON.stringify(formData);

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/updateLinkChangeRequest.php', obj)
      .then((_) => {
        dispatch({
          type: type,
          data: formData,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
        message.error('Update Failed! Try again.');
      });
    return;
  }

  https
    .post('/updateLinkChangeRequest.php', obj)
    .then((_) => {
      dispatch({
        type: type,
        data: formData,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.log(err);
      message.error('Update Failed! Try again.');
    });
};

export const ncoUpdateLinkRequest = (formData) => (dispatch) => {
  const type =
    formData.type === 'ini' ? NCO_UPDATE_LINK_CHANGE : NCO_UPDATE_LINK_CHANGE;
  const obj = JSON.stringify(formData);
  ncoHttps
    .post('/updateLinkChange.php', obj)
    .then((_) => {
      dispatch({
        type: type,
        data: formData,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.log(err);
      message.error('Update Failed! Try again.');
    });
  return;
};
export const ncoDeleteLinkRequest = (formData) => (dispatch) => {
  const type =
    formData.type === 'ini' ? DELETE_LINK_REQUEST : DELETE_LINK_CHANGE_REQUEST;
  const obj = JSON.stringify(formData);
  ncoHttps
    .post('/deleteLinkChangeRequest.php', obj)
    .then((_) => {
      console.log(formData);
      dispatch({
        type: type,
        id: formData.id,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.error(err);
      message.error('Delete Failed! Try again.');
    });
};

export const deleteLinkRequest = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');
  const type =
    formData.type === 'ini' ? NCO_DELETE_LINK_REQUEST : DELETE_LINK_CHANGE;
  const obj = JSON.stringify(formData);
  console.log('DELETE_COMMENTS', obj);

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteLinkChangeRequest.php', obj)
      .then((_) => {
        console.log(formData);
        dispatch({
          type: type,
          id: formData.id,
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });
    return;
  }

  https
    .post('/deleteLinkChangeRequest.php', obj)
    .then((_) => {
      console.log(formData);
      dispatch({
        type: type,
        id: formData.id,
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.log(err);
      message.error('Delete Failed! Try again.');
    });
};

export const deleteLinkChangeRequest = (formData) => (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);

  if (module === MODULE.rm) {
    rmHttps
      .post('/deleteLinkChangeRequest.php', obj)
      .then((_) => {
        console.log(formData);
        dispatch({
          type: DELETE_LINK_CHANGE_REQUEST,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });

    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/deleteLinkChangeRequest.php', obj)
      .then((_) => {
        console.log(formData);
        dispatch({
          type: DELETE_LINK_CHANGE_REQUEST,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });

    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteLinkChangeRequest.php', obj)
      .then((_) => {
        console.log(formData);
        dispatch({
          type: DELETE_LINK_CHANGE_REQUEST,
          id: formData.id,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Delete Failed! Try again.');
      });
    return;
  }

  https
    .post('/deleteLinkChangeRequest.php', obj)
    .then((_) => {
      console.log(formData);
      dispatch({
        type: DELETE_LINK_CHANGE_REQUEST,
        id: formData.id,
      });
    })
    .catch((err) => {
      console.log(err);
      message.error('Delete Failed! Try again.');
    });
};

export const searchInitiationNco = (no) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const module = localStorage.getItem('module');

  ncoHttps
    .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
    .then(({ data }) => {
      dispatch(reportSearchAction(data));
    })
    .catch((err) => {
      dispatch(reportSearchAction([]));
    });
};
export const searchInitiation = (no) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
      .then(({ data }) => {
        dispatch(reportSearchAction(data));
      })
      .catch((err) => {
        dispatch(reportSearchAction([]));
      });
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
      .then(({ data }) => {
        dispatch(reportSearchAction(data));
      })
      .catch((err) => {
        dispatch(reportSearchAction([]));
      });
    return;
  }

  if (module === MODULE.mtf) {
    mtfHttps
      .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
      .then(({ data }) => {
        dispatch(reportSearchAction(data));
      })
      .catch((err) => {
        dispatch(reportSearchAction([]));
      });
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
      .then(({ data }) => {
        dispatch(reportSearchAction(data));
      })
      .catch((err) => {
        dispatch(reportSearchAction([]));
      });
    return;
  }

  https
    .get(`/changeControlSearch.php?organization=${organization}&search=${no}`)
    .then(({ data }) => {
      dispatch(reportSearchAction(data));
    })
    .catch((err) => {
      dispatch(reportSearchAction([]));
    });
};

export const clearSearchInitiation = () => (dispatch) => {
  dispatch(reportSearchAction([]));
};

export const reportSearchAction = (data) => ({
  type: INI_SEARCH,
  data,
});
export const addRetireCapa = (formData, editRetireCapa) => (dispatch) => {
  const obj = JSON.stringify(formData);
  console.log(formData, 'SFdfdf');
  //console.log({
  //    capa_no: formData.capa_no,
  //    nco_no: formData.nco_no,
  //});
  // Assuming you're using ncoHttps for making POST requests
  ncoHttps
    .post(`capa/capaRetire.php`, obj)
    .then((res) => {
      // Handle the success response here, similar to the PUT request
      //getRetireCapa(formData)(dispatch);

      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ADD_Retire_Capa',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.error(err.response.data);
      // Handle the error here, e.g., show an error message to the user
    });
};

export const addEffectivenessCheck =
  (formData, editEffectiveness) => (dispatch) => {
    const name = localStorage.getItem('name');
    const username = localStorage.getItem('username');
    const data = { ...formData };
    const obj = JSON.stringify(data);
    console.log(formData, 'SFdfdf');
    console.log({
      capa_no: formData.capa_no,
      nco_no: formData.nco_no,
    });
    if (editEffectiveness) {
      // Assuming you're using ncoHttps for making PUT requests
      ncoHttps
        .put(`/capa/capaEffective.php`, obj)
        .then((res) => {
          dispatch(
            getTaskDetails({
              capa_no: formData.capa_no,
              id: formData.nco_no,
            })
          );

          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              effective_id: formData.id,
              modalType: 'ADD_Effectiveness_Check',
              modalProps: { show: false },
            })
          );
        })
        .catch((err) => {
          console.error(err.response.data);
          // Handle the error here, e.g., show an error message to the user
        });
      return;
    } else {
      // Assuming you're using ncoHttps for making POST requests
      ncoHttps
        .post(`/capa/capaEffective.php`, obj)
        .then((res) => {
          // Handle the success response here, similar to the PUT request
          dispatch(
            getTaskDetails({
              capa_no: formData.capa_no,
              id: formData.nco_no,
            })
          );
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'ADD_Effectiveness_Check',
              modalProps: { show: false },
            })
          );
        })
        .catch((err) => {
          console.error(err.response.data);
          // Handle the error here, e.g., show an error message to the user
        });
    }
  };

export const getEffectiveness = (formData) => (dispatch) => {
  // const obj = JSON.stringify(formData);
  console.log(formData);
  ncoHttps
    .get(`/capa/capaEffective.php`, {
      params: {
        capa_no: formData.capa_no,
        nco_no: formData.nco_no,
      },
    })
    .then((res) => {
      dispatch({
        type: 'GET_EFFECTIVENESS_DATA',
        data: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: 'GET_EFFECTIVENESS_DATA',
        data: [],
      });
    });
};

export const addRcaDetails = (formData, editRca) => (dispatch) => {
  const role = 'action_owner';
  formData = { ...formData, role, audit_stage: 'Action Owner' };
  const obj = JSON.stringify(formData);
  console.log(editRca);
  if (editRca) {
    ncoHttps
      .put(`rca/edit.php`, obj)
      .then((res) => {
        dispatch(
          getActionItemDetails({
            nco_no: formData.nco_no,
            act_id: formData.action_id,
          })
        );
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ADD_RCA_FORM',
            modalProps: { show: false },
          })
        );
        dispatch({
          type: 'CLEAR_ATTACHMENTS',
        });
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }
  ncoHttps
    .post(`/newRCA.php`, obj)
    .then((res) => {
      dispatch(
        getActionItemDetails({
          nco_no: formData.nco_no,
          act_id: formData.action_id,
        })
      );
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ADD_RCA_FORM',
          modalProps: { show: false },
        })
      );
      dispatch({
        type: 'CLEAR_ATTACHMENTS',
      });
      audit({ ...formData, type: 'rca', rca_no: res.data.rca_no });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const getAllRca = (formData) => (dispatch) => {
  // const obj = JSON.stringify(formData);
  ncoHttps
    .get(`/getRCA.php`, {
      params: {
        nco_no: formData.nco_no,
        action_id: formData.action_id,
      },
    })
    .then((res) => {
      dispatch({
        type: 'SET_RCA',
        data: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteEffectiveness = (formData) => (dispatch) => {
  // console.log(formData)
  const obj = {
    capa_no: formData.capa_no,
    check_no: formData.check_no,
    nco_no: formData.nco_no,
  };
  ncoHttps
    .delete(`/capa/capaEffective.php`, { data: obj })
    .then((response) => {
      dispatch(
        getTaskDetails({
          capa_no: formData.capa_no,
          id: formData.nco_no,
        })
      );
    })
    .catch((error) => {
      console.error('Error deleting data:', error);
      // Handle error scenarios
    });
};
export const deleteRca = (formData) => (dispatch) => {
  // console.log(formData)
  ncoHttps
    .delete(
      `/rca/delete.php?rca_no=${formData.rca_no}&nco_no=${formData.nco_no}&action_id=${formData.action_id}`
    )
    .then((response) => {
      dispatch(
        getActionItemDetails({
          nco_no: formData.nco_no,
          act_id: formData.action_id,
        })
      );
    })
    .catch((error) => {
      console.error('Error deleting data:', error);
      // Handle error scenarios
    });
};

export const deleteCapa = (formData) => (dispatch) => {
  // console.log(formData)
  ncoHttps
    .delete(
      `/capa/delete.php?capa_no=${formData.capa_no}&nco_no=${formData.nco_no}&rca_no=${formData.rca_no}`
    )
    .then((response) => {
      dispatch(
        getActionItemDetails({
          nco_no: formData.nco_no,
          act_id: formData.action_id,
        })
      );
    })
    .catch((error) => {
      console.error('Error deleting data:', error);
      // Handle error scenarios
    });
};

export const getAllCapa = (formbody) => (dispatch) => {
  ncoHttps
    .get(`/getAllCapa.php`, {
      params: {
        nco_no: formbody.nco_no,
        action_id: formbody.action_id,
      },
    })
    .then((res) => {
      dispatch({
        type: 'SET_CAPA',
        data: res.data,
      });
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addCapaDetails = (formData, editCapa) => (dispatch) => {
  const role = 'action_owner';

  formData = { ...formData, role };
  const obj = JSON.stringify(formData);

  if (editCapa) {
    ncoHttps
      .put(`capa/edit.php`, obj)
      .then((res) => {
        dispatch(
          getActionItemDetails({
            nco_no: formData.nco_no,
            act_id: formData.action_id,
          })
        );
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'ADD_CAPA_FORM',
            modalProps: { show: false },
          })
        );
      })
      .catch((err) => {
        console.log(err.response.data);
      });

    return;
  }
  ncoHttps
    .post(`/newCapa.php`, obj)
    .then((res) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ADD_CAPA_FORM',
          modalProps: { show: false },
        })
      );
      dispatch(
        getActionItemDetails({
          nco_no: formData.nco_no,
          act_id: formData.action_id,
        })
      );
      audit({
        ...formData,
        type: 'rca',
        rca_no: res.data.rca_no,
        capa_no: res.data.capa_no,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addCapaImplementation = (formData) => (dispatch) => {
  const role = localStorage.getItem('role');
  const action_owner = localStorage.getItem('username');
  formData = { ...formData, role, action_owner, id: formData.nco_no };
  const obj = JSON.stringify(formData);
  console.log(formData);

  ncoHttps
    .post(`/capa/attachment.php`, obj)
    .then((res) => {
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'ADD_CAPA_PLAN',
          modalProps: { show: false },
        })
      );
      dispatch(getTaskDetails(formData))();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const moveCapaImplementation = (formData, history) => (dispatch) => {
  const role = localStorage.getItem('role');
  const action_owner = `${localStorage.getItem('name')} (${localStorage.getItem(
    'username'
  )})`;
  const mailObj = {
    email_type: 'capa_implementation',
    stage: 'CAPA Plan Verification',
    capa_items: [formData.capa_no],
    stage_index: 1,
    action_by: localStorage.getItem('username'),
    assigned_comment: formData.activity_comments,
    nco_no: formData.nco_no,
  };
  formData = { ...formData, role, action_owner };
  const obj = JSON.stringify(formData);
  console.log(formData);

  ncoHttps
    .post(`/moveTaskToInitiator.php`, obj)
    .then((res) => {
      sendEmail(mailObj);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      history.push('/nco/implementation');
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addActionReview = (formData, history) => (dispatch) => {
  const obj = JSON.stringify(formData);
  const actionItem = formData.nco_action_item.map((item) => item.action_id);
  const mailObj = {
    email_type: 'nco_action_create',
    stage: 'Action Item Notification',
    stage_index: 1,
    action_items: actionItem,
    action_by: localStorage.getItem('username'),
    nco_no: formData.nco_no,
  };

  console.log(formData);

  ncoHttps
    .post(`/newFunctionalReview.php`, obj)
    .then(() => {
      // console.log('my name sidjsms', obj);
      sendEmail(mailObj);
      message.success({
        content: 'Action Item Submitted',
        key: 'updatable',
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      //  history.push('/nco/initiation');
      dispatch(
        getInitiationDetails({
          nco_no: formData.nco_no,
          type: 'main',
        })
      );
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};
export const addFunctionReview = (formData, history) => (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);

  if (module === MODULE.rm) {
    const mailObj = {
      email_type: 'func_review_created',
      stage: 'Risk Evaluation',
      stage_index: 1,
      action_by: localStorage.getItem('username'),
      risk_id: formData.risk_id,
    };

    rmHttps
      .post(`/newFunctionalReview.php`, obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Functional Review Submitted',
          key: 'updatable',
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/rm/initiation');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  if (module === MODULE.dnc) {
    console.log('BSXO2184');
    const mailObj = {
      email_type: 'd_action_create',
      stage: 'Deviation Evaluation',
      stage_index: 1,
      action_by: localStorage.getItem('username'),
      d_no: formData.d_no,
    };

    dncHttps
      .post(`/newFunctionalReview.php`, obj)
      .then(() => {
        sendEmail(mailObj);
        message.success({
          content: 'Functional Review Submitted',
          key: 'updatable',
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/dnc/initiation');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  const mailObj = {
    email_type: 'func_review_created',
    stage: 'Change Control Evaluation',
    stage_index: 1,
    action_by: localStorage.getItem('username'),
    cc_no: formData.cc_no,
  };

  if (module === MODULE.cmm) {
    cmmHttps
      .post(`/newFunctionalReview.php`, obj)
      .then(() => {
        console.log('my name sidjsms', obj);
        sendEmail(mailObj);
        message.success({
          content: 'Functional Review Submitted',
          key: 'updatable',
        });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        history.push('/iControl/initiation');
      })
      .catch((err) => {
        console.log(err.response.data);
      });
    return;
  }

  https
    .post(`/newFunctionalReview.php`, obj)
    .then(() => {
      console.log('my name sidjsms', obj);
      sendEmail(mailObj);
      message.success({
        content: 'Functional Review Submitted',
        key: 'updatable',
      });
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      history.push('/iControl/initiation');
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const addActionOwnerReview = (formData, history) => (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);

  const actionItem = [formData.action_id];
  const mailObj = {
    email_type: 'nco_action_item_submit',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_items: actionItem,
    action_by: localStorage.getItem('username'),
    nco_no: formData.nco_no,
  };
  const mailObj2 = {
    email_type: 'vendor_action_submission',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_items: actionItem,
    action_by: localStorage.getItem('username'),
    nco_no: formData.nco_no,
    indicator: 'verifier',
  };
  // console.log(formData);
  ncoHttps
    .post(`/forwardRCAtoVerifier.php`, formData)
    .then(() => {
      if (formData.type == 'vendor') {
        sendEmail(mailObj2);
      } else {
        sendEmail(mailObj);
      }
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
      if (formData.type === 'vendor') {
        history.push(`/vendor/capa`);
      } else {
        history.push(`/nco/RCAInvestigation`);
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};

export const acceptOrRejectRca = (formData) => (dispatch) => {
  const username = localStorage.getItem('username');
  const obj = JSON.stringify(formData);
  console.log(obj);
  const mailObj = {
    email_type: 'rca_reject',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_by: username,
    rca_items: [formData.rca_no],
    action_items: [formData.action_id],
    route_comment: formData.verifer_comment,
    indicator: 'action_owner',
    nco_no: formData.nco_no,
  };
  const mailObj2 = {
    email_type: 'rca_reject',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_by: username,
    rca_items: [formData.rca_no],
    action_items: [formData.action_id],
    route_comment: formData.verifer_comment,
    indicator: 'vendor',
    nco_no: formData.nco_no,
  };
  const mailObj3 = {
    email_type: 'nco_action_item_complete',
    stage: 'CAPA Plan Verification',
    stage_index: 2,
    action_by: username,
    rca_items: [formData.rca_no],
    action_items: [formData.action_id],
    assigned_comment: formData.verifer_comment,
    nco_no: formData.nco_no,
  };
  // console.log(formData);
  ncoHttps
    .post(`/acceptOrRejectRCA.php`, formData)
    .then(() => {
      getInitiationDetails({ nco_no: formData.nco_no, type: 'main' })(dispatch);
      getActionItemNotification(formData.nco_no)(dispatch);
      if (formData.vendorStatus && formData.verifier_response === 'false') {
        sendEmail(mailObj2);
      } else if (formData.verifier_response === 'false') {
        sendEmail(mailObj);
      } else if (formData.verifier_response === 'true') {
        sendEmail(mailObj3);
      }
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        hideModal({
          type: 'HIDE_MODAL',
          modalType: 'SIGNATURE_FORM',
          modalProps: { show: false },
        })
      );
    });
};

export const updateFunctionReview =
  (formData, functional_action) => (dispatch) => {
    const module = localStorage.getItem('module');
    const obj = JSON.stringify(formData);

    if (module === MODULE.rm) {
      rmHttps
        .post(`/updateFunctionalReview.php`, obj)
        .then(() => {
          sendEmail({ ...formData, functional_action });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          dispatch({
            type: UPDATE_FUNCTIONAL_REVIEW,
            data: formData,
          });
          message.success('Successfully Updated');
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    if (module === MODULE.dnc) {
      dncHttps
        .post(`/updateFunctionalReview.php`, obj)
        .then(() => {
          sendEmail({ ...formData, functional_action });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          dispatch({
            type: UPDATE_FUNCTIONAL_REVIEW,
            data: formData,
          });
          message.success('Successfully Updated');
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    if (module === MODULE.cmm) {
      cmmHttps
        .post(`/updateFunctionalReview.php`, obj)
        .then(() => {
          sendEmail({ ...formData, functional_action });
          dispatch(
            hideModal({
              type: 'HIDE_MODAL',
              modalType: 'SIGNATURE_FORM',
              modalProps: { show: false },
            })
          );
          dispatch({
            type: UPDATE_FUNCTIONAL_REVIEW,
            data: formData,
          });
          message.success('Successfully Updated');
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      return;
    }

    https
      .post(`/updateFunctionalReview.php`, obj)
      .then(() => {
        sendEmail({ ...formData, functional_action });
        dispatch(
          hideModal({
            type: 'HIDE_MODAL',
            modalType: 'SIGNATURE_FORM',
            modalProps: { show: false },
          })
        );
        dispatch({
          type: UPDATE_FUNCTIONAL_REVIEW,
          data: formData,
        });
        message.success('Successfully Updated');
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const deleteFuncReview = (formData, mailBody) => (dispatch) => {
  const module = localStorage.getItem('module');
  const obj = JSON.stringify(formData);

  if (module === MODULE.rm) {
    rmHttps
      .post('/deleteFunctionalReview.php', obj)
      .then(() => {
        sendEmail(mailBody);
        dispatch({
          type: DELETE_FUNC_REVIEW,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post('/deleteFunctionalReview.php', obj)
      .then(() => {
        sendEmail(mailBody);
        dispatch({
          type: DELETE_FUNC_REVIEW,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post('/deleteFunctionalReview.php', obj)
      .then(() => {
        sendEmail(mailBody);
        dispatch({
          type: DELETE_FUNC_REVIEW,
          id: formData.id,
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/deleteFunctionalReview.php', obj)
    .then(() => {
      sendEmail(mailBody);
      dispatch({
        type: DELETE_FUNC_REVIEW,
        id: formData.id,
      });
    })
    .catch((err) => console.log(err));
};

export const getDocumentNoList = (value) => async (dispatch) => {
  const organization = localStorage.getItem('organization');
  try {
    const res = await idocHttps.get(
      `/getdocuments?org=${organization}&search=${value}`
    );
    dispatch({
      type: DOC_NO_LIST,
      data: res.data,
    });
  } catch (error) {
    message.error('Cannot find Document Number');
  }
};

export const getDocumentDetail = (value, cb) => async (dispatch) => {
  try {
    const res = await idocHttps.get(
      `/getdocumentdata?document_no=${value.document_no}&version_no=${value.version_no}`
    );
    dispatch({
      type: DOC_NO_DETAIL,
      data: res.data[0],
    });
    cb(
      ['doc_type', 'doc_title'],
      [res.data[0].category, res.data[0].doc_title]
    );
  } catch (error) {}
};

export const forwardToVerification = (formBody, listData) => (dispatch) => {
  console.log(formBody);
  const obj = {
    nco_nos: formBody,
    current_handler: localStorage.getItem('username'),
  };
  ncoHttps
    .post('/forwardToVerification.php', obj)
    .then(() => {
      const filteredData = listData?.filter(
        (item) => !item.nco_no.includes(formBody)
      );
      dispatch({
        type: GET_INITIATION_DATA,
        data: filteredData,
        excelData: [],
      });
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const getActionItemDetails = (formBody) => (dispatch) => {
  console.log(formBody);

  ncoHttps
    .post('/getActionItemDetails.php', formBody)
    .then(({ data }) => {
      console.log(data);
      dispatch({ type: ACTION_ITEM_DETAILS, data });
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const saveImmediateCorrection = (formBody) => (dispatch) => {
  console.log(formBody);

  ncoHttps
    .post('/saveImmediateCorrection.php', formBody)
    .then(({ data }) => {
      message.success('Immediate Correction Details is Saved');
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const saveTraceabilityInformation = (formBody) => (dispatch) => {
  console.log(formBody);

  ncoHttps
    .post('/savetraceabilityinformation.php', formBody)
    .then(({ data }) => {
      message.success('Traceability Information is Saved');
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const addVendor = (formData) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(`${process.env.REACT_APP_IACCESS_URL}/addVendor/`, formData, config)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const updateVendor = (formData) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/updateVendor/`,
        formData,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const deleteVendor = (formData) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/deleteVendor/`,
        formData,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const allVendor = (formData) => (dispatch) => {
  try {
    const userToken = localStorage.getItem('access_token');
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };

    axios
      .post(
        `${process.env.REACT_APP_IACCESS_URL}/viewAllVendor/`,
        formData,
        config
      )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const CC_list = () => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const module = localStorage.getItem('module');

  if (module === MODULE.rm) {
    rmHttps
      .get(`/cc_no_list.php?cci_organisation=${organization}`)
      .then(({ data }) => {
        dispatch({ type: CC_NO_LIST, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .get(`/cc_no_list.php?cci_organisation=${organization}`)
      .then(({ data }) => {
        dispatch({ type: CC_NO_LIST, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .get(`/cc_no_list.php?cci_organisation=${organization}`)
      .then(({ data }) => {
        dispatch({ type: CC_NO_LIST, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .get(`/cc_no_list.php?cci_organisation=${organization}`)
    .then(({ data }) => {
      dispatch({ type: CC_NO_LIST, data: data });
    })
    .catch((err) => console.log(err));
};

export const NCO_List = () => (dispatch) => {
  ncoHttps
    .get(`/nco_no_list.php`)
    .then(({ data }) => {
      dispatch({ type: CC_NO_LIST, data: data });
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};

export const IDOCList = () => (dispatch) => {
  idocHttps
    .get(`/fetchDocumentNo`)
    .then(({ data }) => {
      dispatch({
        type: 'CC_NO_LIST',
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const CC_filter = (formBody) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const module = localStorage.getItem('module');
  const body = {
    cci_organisation: organization,
  };
  if (module === MODULE.rm) {
    rmHttps
      .post(`/filter_cc.php`, body)
      .then(({ data }) => {
        dispatch({ type: CC_FILTER, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.dnc) {
    dncHttps
      .post(`/filter_cc.php`, body)
      .then(({ data }) => {
        dispatch({ type: CC_FILTER, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  if (module === MODULE.cmm) {
    cmmHttps
      .post(`/filter_cc.php`, body)
      .then(({ data }) => {
        dispatch({ type: CC_FILTER, data: data });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post(`/filter_cc.php`, body)
    .then(({ data }) => {
      dispatch({ type: CC_FILTER, data: data });
    })
    .catch((err) => console.log(err));
};

export const getNCO_filter = () => (dispatch) => {
  ncotestHttps
    .get(`/filter_nco.php`)
    .then(({ data }) => {
      dispatch({ type: NCO_FILTER, data: data });
    })
    .catch((err) => {
      message.error('Error Submitting! Try again.');
      console.log(err);
    });
};
